<template>
  <div class="community">
    <headbar />

    <div class="container">
      <div class="level-row">
        <div class="level-item">{{ $t('级别') }}：{{ teamData.xlevelName }}</div>
        <div class="level-item">{{ $t('速度') }}：{{ teamData.vlevelName }}</div>
      </div>

      <div class="total-card">
        <div class="total-row">
          <div class="total-col q-mr-sm">
            <div class="total-label">{{ $t('已挖出 STC') }}</div>
            <div class="total-value">{{ teamData.shifangDay }}</div>
          </div>
          <div class="total-col">
            <div class="total-label">{{ $t('算力') }}（POT）</div>
            <div class="total-value">{{ teamData.suanli }}</div>
          </div>
        </div>
        <div class="total-row q-mt-md">
          <div class="total-col q-mr-sm">
            <div class="total-label">{{ $t('个人入金') }}</div>
            <div class="total-value">{{ teamData.lskTouziBei }}</div>
          </div>
          <div class="total-col">
            <div class="total-label">{{ $t('社区总入金') }}</div>
            <div class="total-value">{{ teamData.teamyejiTouziBei }}</div>
          </div>
        </div>
        <div class="total-row q-mt-md">
          <div class="total-col q-mr-sm">
            <div class="total-label">{{ $t('我的大区业绩') }}</div>
            <div class="total-value">{{ teamData.daqu }}</div>
          </div>
          <div class="total-col">
            <div class="total-label">{{ $t('我的小区业绩') }}</div>
            <div class="total-value">{{ teamData.xiaoqu }}</div>
          </div>
        </div>
        <div class="total-row q-mt-md">
          <div class="total-col q-mr-sm">
            <div class="total-label">{{ $t('团队竞技流水') }}</div>
            <div class="total-value">{{ teamData.teamyejiJingcaiWin }}</div>
          </div>
          <div class="total-col q-mr-sm">
            <div class="total-label">{{ $t('社区每⽇入⾦') }}</div>
            <div class="total-value">{{ teamData.teamDayTouzi }}</div>
          </div>
        </div>
      </div>

      <div class="team">
        <!-- <div class="tabs">
          <div class="tabs-item" :class="{'tabs-active': active == 1}" @click="tabsChange(1)">社区成员</div>
          <div class="tabs-item" :class="{'tabs-active': active == 2}" @click="tabsChange(2)">直属好友</div>
        </div> -->
        <div class="team-title">{{ $t('我的直推') }}</div>
        <div class="team-list">
          <div class="team-item" v-for="(item, index) in teamData.list" :key="index">
            <div class="row items-center justify-betweent">
              <div class="col">{{ abbr(item.address, 4, 4) }}</div>
              <div class="col">{{ item.rdt.split(" ")[0] }}</div>
              <div class="row1" @click="onClick(item)">
                <q-icon v-if="item.more" name="keyboard_arrow_left" size="21px"></q-icon>
                <q-icon v-else name="keyboard_arrow_down" size="21px"></q-icon>
              </div>
            </div>
            <div class="list-more" v-show="item.more">
              <div class="list-more-value">
                <div>
                  <span>{{ $t('等级') }}：</span>
                  <span class="more-value">{{ item.vlevelName }}</span>
                </div>
                <div style="display: flex;">
                  <div style="flex: 1;">
                    <div>
                      <span>{{ $t('有效人数') }}：</span>
                      <span class="more-value">{{ item.youxiao }}</span>
                    </div>
                    <div>
                      <span>{{ $t('大区业绩') }}：</span>
                      <span class="more-value">{{ item.daqu }}</span>
                    </div>
                  </div>
                  <div style="flex: 1;">
                    <div>
                      <span>{{ $t('团队人数') }}：</span>
                      <span class="more-value">
                        {{ item.teamcount }}
                      </span>
                    </div>
                    <div>
                      <span>{{ $t('小区业绩') }}：</span>
                      <span class="more-value">
                        {{ item.xiaoqu }}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <empty :text="$t('暂无记录')" v-if="teamData.list.length == 0" />
        </div>
      </div>
    </div>

    <check-register @refresh="refresh"></check-register>

    <tab-bar active="4" />
  </div>
</template>

<script>
import { ref } from 'vue'
import CheckRegister from '@/components/CheckRegister'
import CustomComponent from "@/components/CustomComponent";

export default {
  components: {
    CheckRegister
  },
  setup() {
    return {
      us: ref(null),
      active: ref(1),
      teamData: ref({
        list: []
      })
    }
  },
  created() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    if (this.us) {
      // this.getTeamPageData()
      this.getTeamTotal()
    }
  },
  methods: {
    refresh() {
      this.getTeamTotal()
    },
    tabsChange(e) {
      this.active = e
    },
    abbr(val, start, end) {
      if (val && val.length > 20) {
        let newVal = val.replace(
          val.substring(start, val.length - end),
          "****"
        )
        return newVal
      } else {
        return val
      }
    },
    onClick(item) {
      item.more = item.more ? false : true
    },
    getTeamTotal() {
      let _this = this
      _this.$request.post(
        "api/Page/UserTeamList",
        {
          userid: _this.us.userid,
        },
        (res) => {
          if (res.data.code == 100) {
            let data = res.data.data
            data.list.forEach(item => {
              item["more"] = false
            });
            _this.teamData = data
          }
        }
      )
    }
  }
}
</script>

<style scoped>
.community {
  background-image: url(~@/assets/img/base/page-footer-bg.png);
  background-size: 100% 320px;
  background-position: center bottom;
  background-repeat: no-repeat;
}

.container {
  padding: 10px 20px;
}

.level-row {
  display: flex;
  justify-content: space-between;
}

.level-item {
  width: 48%;
  height: 38px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
  color: #fff;
}

.total-card {
  margin-top: 8px;
  padding: 15px;
  border: 1px solid #884DDA;
  box-shadow: 0px 0px 12.6px 0px #8247E5 inset;
  background: #121C2E;
  border-radius: 20px;
}

.total-row {
  display: flex;
  justify-content: space-between;
}

.total-col {
  flex: 1;
}

.total-label {
  color: #4E5F7E;
  font-size: 12px;
}

.total-value {
  margin-top: 8px;
  padding: 0 10px;
  height: 32px;
  line-height: 32px;
  background: #081121;
  border: 1px solid #884DDA;
  border-radius: 10px;
  color: #fff;
  font-size: 12px;
}

.team {
  margin-top: 15px;
  padding-top: 40px;
  background-image: url(~@/assets/img/base/ranking-round.png);
  background-size: 100% auto;
  background-position: top;
  background-repeat: no-repeat;
}

.tabs {
  padding: 0 5px;
  height: 44px;
  display: flex;
  align-items: center;
  box-sizing: border-box;
  background: #121C2E;
  border-radius: 99px;
}

.tabs-item {
  flex: 1;
  height: 35px;
  line-height: 35px;
  border-radius: 99px;
  font-size: 13px;
  font-weight: bold;
  text-align: center;
  color: #4E5F7E;
}

.tabs-active {
  color: #fff;
  background: linear-gradient(180deg, #884DDA 0%, #482974 100%);
}

.team-list {
  padding: 15px;
  margin-top: 8px;
  min-height: 240px;
  border-radius: 20px;
  background: #121C2E;
}

.team-item {
  margin-bottom: 5px;
  padding: 10px;
  color: #fff;
  background: #081121;
  font-size: 13px;
  border-radius: 10px;
}

.team-item:last-child {
  margin-bottom: 0;
}

.team-title {
  font-size: 25px;
  font-weight: bold;
  text-align: center;
  color: #fff;
}

.list-more {
  margin-top: 10px;
  color: #fff;
  padding: 0 0 0 10px;
}

.list-more-value {
  text-align: left;
}

.more-value {
  color: #884DDA;
  overflow-wrap: break-word;
}
</style>